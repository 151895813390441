<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Vendor'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/vendor`" title="Vendor" :columns="columns"
                routerpath="/master/account/addvendor" :formOptions="formOptions" :newPage="true" :edit="edit"
                :add="add" :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import { BRow, BCol } from "bootstrap-vue";

import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
    BRow,
    BCol,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",

      // tabs: [
      //   { name: 'Ledger', route: '/master/accounting/ledger', icon: 'WindIcon' },
      //   { name: 'AccountGroup', route: '/master/accounting/accountgroup', icon: 'UsersIcon' },
      //   { name: 'BankAccountMaster', route: '/master/accounting/bankaccount', icon: 'GridIcon' },
      //   { name: 'BankStatement', route: '/master/accounting/bankstatement', icon: 'FileTextIcon' },
      //   { name: 'Vendor', route: '/master/accounting/vendor', icon: 'SidebarIcon' },
      //   { name: 'Vendortype', route: '/master/accounting/vendortype', icon: 'TypeIcon' },
      //   { name: 'Vendorcategory', route: '/master/accounting/vendorcategory', icon: 'OctagonIcon' },
      //   { name: 'Broker', route: '/master/accounting/broker', icon: 'UserIcon' },
      //   { name: 'Farmer', route: '/master/accounting/farmer', icon: 'FramerIcon' },
      //   { name: 'PurchaseBuyer', route: '/master/accounting/purchasebuyer', icon: 'TriangleIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Vendor" : "Add Vendor"}`,
        submitRouterPath: "/master/account",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/vendor`,
        inputFields: [],
        getEditValue: `${baseApi}/getvendorById`
      },
      viewFields: [
        {
          label: 'Company Name',
          field: 'companyname',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Date',
          field: 'date',
          type: 'dropdown',
          responseValue: 'name',
          class: 'col-md-4',
        },
        {
          label: 'Vendor Type',
          field: 'vendortype',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Category',
          field: 'category',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Address 1',
          field: 'address1',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Address 2',
          field: 'address2',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'At',
          field: 'at',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Post Office',
          field: 'postoffice',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Taluka',
          field: 'taluka',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'pin Code',
          field: 'pincode',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'District',
          field: 'city',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Branch',
          field: 'branchname',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Area',
          field: 'area',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'State',
          field: 'state',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Contact Person',
          field: 'contactperson',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Mobile',
          field: 'mobile',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Email',
          field: 'email',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Fax no',
          field: 'faxno',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Opening Balance',
          field: 'openingbalance',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Cr/Dr',
          field: 'crdr',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Group',
          field: 'group',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Alias',
          field: 'alias',
          type: 'text',
          class: 'col-md-4',
        },



        {
          label: 'VAT No',
          field: 'vatno',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'CST NO',
          field: 'cstno',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'PAN NO.',
          field: 'panno',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Service Tax No',
          field: 'servicetaxno',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'GST NO.',
          field: 'gstno',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Bank',
          field: 'bank',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Branch',
          field: 'branch',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'A/C No',
          field: 'accno',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'IFSC Code',
          field: 'ifsccode',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Payment Term (in days)',
          field: 'paymentterms',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'TDS',
          field: 'tds',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'TDS Group',
          field: 'tdsgroup',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'TDS%',
          field: 'tdspr',
          type: 'text',
          class: 'col-md-4',
        },
        {
          label: 'Cancle Cheque',
          field: 'documents.cancelcheque',
          type: 'attachment',
          class: 'col-md-4',
        },
        {
          label: 'Pancard Copy',
          field: 'documents.pancardcopy',
          type: 'attachment',
          class: 'col-md-4',
        },
        {
          label: 'Visiting Card',
          field: 'documents.visitingcard',
          type: 'attachment',
          class: 'col-md-4',
        },
      ],
      columns: [
        {
          label: "Date",
          field: "date",
          type: 'datetime',
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Vendor Type",
          field: "vendortype",
          type: "dropdown",
          url: "getvendortype",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Vendor Type",
          },
        },
        {
          label: "Contact Person",
          field: "contactperson",
          filterOptions: {
            enabled: true,
            placeholder: "Search Contact Person",
          },
        },
        {
          label: "Company Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Company Name",
          },
        },
        {
          label: "At",
          field: "at",
          filterOptions: {
            enabled: true,
            placeholder: "Search At",
          },
        },
        {
          label: "Group",
          field: "group",
          filterOptions: {
            enabled: true,
            placeholder: "Search Group",
          },
        },
        {
          label: "GST No",
          field: "gstno",
          filterOptions: {
            enabled: true,
            placeholder: "Search GST No",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Vendor") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
